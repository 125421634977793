//首页请求
import request from './request'

//入口头部尾部信息
const getInfo = ()=> request.get('/reheraInfo.json')

//副导航栏
const subNav = ()=> request.get('/subNav.json')

//首页banner
const banner = ()=> request.get('/home/banner.json')

//关于瑞和数据
const aboutRehera = ()=> request.get('/home/aboutRehera.json')

//产品中心数据
const productCenter = ()=> request.get('/home/productCenter.json')

//合作伙伴数据
const partner = ()=> request.get('/home/partner.json')

export default{
    getInfo,
    subNav,
    banner,
    aboutRehera,
    productCenter,
    partner
}