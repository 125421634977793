import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  //首页
  {
    path: "/home",
    name: "home",
    meta: {
      title: "首页",
    },
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  //关于我们
  {
    path: "/about",
    name: "about",
    meta: {
      title: "关于瑞和",
    },
    children: [
      //企业介绍
      {
        path: "/detail",
        name: "detail",
        meta: {
          title: "企业介绍"
        },
        component: () =>
          import(/* webpackChunkName: "detail" */ "../views/about/Detail.vue"),
      },
      //产品中心
      {
        path: "/product",
        name: "product",
        meta: {
          title: "产品与服务"
        },
        component: () =>
          import(/* webpackChunkName: "product" */ "../views/about/Product.vue"),
      },
      //合作伙伴
      {
        path: "/partner",
        name: "partner",
        meta: {
          title: "合作伙伴"
        },
        component: () =>
          import(/* webpackChunkName: "partner" */ "../views/about/Partner.vue"),
      },
      //成功案例
      {
        path: "/case",
        name: "case",
        meta: {
          title: "成功案例"
        },
        component: () =>
          import(/* webpackChunkName: "case" */ "../views/about/Case.vue"),
      },
      //联系我们
      {
        path: "/contact",
        name: "contact",
        meta: {
          title: "联系我们"
        },
        component: () =>
          import(/* webpackChunkName: "contact" */ "../views/about/Contact.vue"),
      },
    ],
    redirect:'/detail',
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
