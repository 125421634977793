<template>
  <div id="app">
    <!-- 头部 -->
    <div class="header">
      <img :src="dataList.logoImg" alt="" class="bgImg" />
      <img :src="dataList.logo" alt="" />
      <ul>
        <li ref="nav"><router-link to="/home">首页</router-link></li>
        <li ref="nav"><router-link to="/detail">关于我们</router-link></li>
        <li ref="nav">
          <router-link to="/product">产品中心</router-link>
        </li>
        <li ref="nav">
          <router-link to="/partner">合作伙伴</router-link>
        </li>
        <li ref="nav"><router-link to="/case">成功案例</router-link></li>
        <li ref="nav">
          <router-link to="/contact">联系我们</router-link>
        </li>
      </ul>
    </div>
    <router-view />
    <!-- 尾部 -->
    <div class="footer">
      <div class="top">
        <img :src="dataList.logo" alt="" />
        <div class="list">
          <div v-for="(v, i) in dataList.contect" :key="i">
            <img :src="v.img" alt="" />
            <span>{{ v.text }}</span>
          </div>
        </div>
        <img :src="dataList.QRcode" alt="" />
      </div>
      <div class="bottom">
        {{ dataList.copyRight }}
        <span><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802041802"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img :src="dataList.beian" style="float:left;margin-top: 4px;" />
            <p style="float:left;height:20px;line-height:30px;margin: 0px 0px 0px 5px; color:#fff;">京公网安备
              11010802041802号</p>
          </a></span>

        <span>{{ dataList.icp }}</span>
        <span>{{ dataList.copyRight2 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import request from "./utils/home";
export default {
  data() {
    return {
      dataList: {},
      routeFlag: 0,
    };
  },
  mounted() {
    this.getData(); //入口文件固定头部尾部数据
    // this.routeFlag = this.$route.meta.index
  },
  methods: {
    getData() {
      request.getInfo("reheraInfo.json").then((res) => {
        this.dataList = res.data;
        // console.log(this.dataList);
      });
    },
    //点击变色
    changeColor(index) {
      this.$refs.nav.forEach((v, i) => {
        v.style.opacity = 0.68;
      });
      this.$refs.nav[index].style.opacity = 1;
    },
  },
};
</script>

<style lang="less">
#app {
  min-width: 800px;
}

.header {
  width: 100%;
  height: 64px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .bgImg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }

  ul {
    margin: 28px 0;
    white-space: nowrap;
  }

  li {
    cursor: pointer;
    text-align: center;
    float: left;
    height: 17px;
    font-size: 16px;

    a {
      color: #ffffff;
      line-height: 11px;
      opacity: 0.68;
      margin-right: 34px;
      white-space: nowrap;
      text-decoration: none;
    }

    .router-link-exact-active {
      opacity: 1 !important;
    }
  }
}

.footer {
  width: 100%;
  background: #4b87a3;

  .top {
    padding-top: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }

  .bottom {
    width: 100%;
    padding: 11px 0 28px 0;
    text-align: center;
    height: 15px;
    font-size: 12px;
    color: #ffffff;
    line-height: 11px;

    span {
      margin-left: 20px;
    }
  }

  .list {
    div {
      margin-bottom: 33px;
    }

    span {
      width: 93px;
      height: 12px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 11px;
      margin-left: 8px;
    }
  }
}
</style>
